import { BILLING_ADDRESS_DELETE } from '../../../types'

const initialState = {
	isLoading: false,
	isError: false,
	alert: false,
	data: null,
	errorMessage: null,
	modalError: false,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${BILLING_ADDRESS_DELETE}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
				data: null,
				errorMessage: null,
			}
		case `${BILLING_ADDRESS_DELETE}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
				alert: true,
				errorMessage: null,
			}
		case `${BILLING_ADDRESS_DELETE}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
				data: null,
				errorMessage: action.payload.message,
				modalError: true,
			}
		case `${BILLING_ADDRESS_DELETE}_CLEAR`:
			return {
				...state,
				isLoading: false,
				isError: false,
				alert: false,
				errorMessage: null,
			}
		case `CLOSE_MODAL_${BILLING_ADDRESS_DELETE}`:
			return {
				...state,
				modalError: false,
			}
		default:
			return state
	}
}
