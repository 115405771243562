import { RESET_PASSWORD, RESET_PASSWORD_USER_DETAIL } from '../../types'
import { combineReducers } from 'redux'
const initialState = {
	data: [],
	isLoading: false,
	isError: false,
}

const resPassUserDetailReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${RESET_PASSWORD_USER_DETAIL}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${RESET_PASSWORD_USER_DETAIL}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
			}
		case `${RESET_PASSWORD_USER_DETAIL}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
			}
		default:
			return state
	}
}

const resPasswordReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${RESET_PASSWORD}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${RESET_PASSWORD}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
			}
		case `${RESET_PASSWORD}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
				error: action.payload.err,
			}
		default:
			return state
	}
}

export default combineReducers({
	userDetail: resPassUserDetailReducer,
	resetPassword: resPasswordReducer,
})
