// import { SEARCH_INVOICE, REMOVE_INVOICE, SELECT_INVOICE } from '../../../types'
import { SEARCH_INVOICE } from '../../../types'

const initialState = {
	isLoading: false,
	isEerror: false,
	data: [
		{
			value: '',
			label: '',
			amount_residual_signed: '',
		},
	],
	total: 0,
	selectedInvoice: [],
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${SEARCH_INVOICE}_PENDING`:
			return {
				...state,
				isLoading: true,
				isEerror: false,
			}
		case `${SEARCH_INVOICE}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isEerror: false,
				data: action.payload.data,
			}
		case `${SEARCH_INVOICE}_REJECTED`:
			return {
				...state,
				isEerror: true,
				isLoading: false,
			}
		case 'CALCULATE_TOTAL':
			return {
				...state,
				total: action.payload.data,
			}
		case 'SELECT_INVOICE':
			return {
				...state,
				selectedInvoice: action.payload.data,
			}
		default:
			return state
	}
}
