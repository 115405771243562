import { CATEGORY } from '../../types'

const initialValue = {
	data: { i: null },
}

export default (state = initialValue, action) => {
	if (action.type == CATEGORY) {
		return {
			...state,
			data: { i: action.payload.data.i },
		}
	}

	return state
}
