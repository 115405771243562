import { MOU_DELIVERY_ADDRESS, SET_DEFAULT } from '../../types'

const initialState = {
	isLoading: false,
	isError: false,
	data: [],
	defaultAddress: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${MOU_DELIVERY_ADDRESS}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${MOU_DELIVERY_ADDRESS}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${MOU_DELIVERY_ADDRESS}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
				data: action.payload.data,
			}
		case `${SET_DEFAULT}_MOU_DELIVERY_ADDRESS`:
			return {
				...state,
				defaultAddress: action.payload.data,
			}
		default:
			return state
	}
}
