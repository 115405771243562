/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from 'react'
import { Provider } from 'react-redux'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateMomentUtils from '@date-io/moment'
import MuiWrapper from '../../mui-root-wrapper'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './createStore'
import 'moment/locale/id'

const Wrapper = ({ element }) => {
	// if (process.env.NODE_ENV === 'development') {
	// 	return <Provider store={store()}>{element}</Provider>
	// }
	// return (
	// 	<Provider store={store()}>
	// 		<IndexPage />
	// 	</Provider>
	// )
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<MuiWrapper>
					<MuiPickersUtilsProvider utils={DateMomentUtils} locale='id'>
						{element}
					</MuiPickersUtilsProvider>
				</MuiWrapper>
			</PersistGate>
		</Provider>
	)
}

export default Wrapper
