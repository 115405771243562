import { CONFIRM_PAYMENT } from '../../../types'

const initialState = {
	isLoading: false,
	isError: false,
	data: null,
}

export default (s = initialState, action) => {
	switch (action.type) {
		case `${CONFIRM_PAYMENT}_PENDING`:
			return {
				...s,
				isError: false,
				isLoading: true,
			}
		case `${CONFIRM_PAYMENT}_FULFILLED`:
			return {
				...s,
				isLoading: false,
				isError: false,
				data: action.payload.data.data,
			}
		case `${CONFIRM_PAYMENT}_REJECTED`:
			return {
				...s,
				isLoading: false,
				isError: true,
			}
		default:
			return s
	}
}
