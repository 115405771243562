import { SAVE_MOU_ITEMS } from '../../types'

const initialState = {
	data: [],
}
export default (state = initialState, action) => {
	switch (action.type) {
		case SAVE_MOU_ITEMS:
			return {
				data: action.payload.data,
			}
		default:
			return state
	}
}
