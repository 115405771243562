import { BANK_UPDATE } from '../../types'

const initialState = {
	isLoading: false,
	isLoadingData: false,
	isError: false,
	alert: false,
	data: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${BANK_UPDATE}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
				data: null,
			}
		case `${BANK_UPDATE}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
				alert: true,
			}
		case `${BANK_UPDATE}_LOAD_DATA`:
			return {
				...state,
				isLoadingData: true,
				isLoading: false,
				isError: false,
			}
		case `${BANK_UPDATE}_DATA`:
			return {
				...state,
				isLoadingData: false,
				isError: false,
				data: action.payload.data,
			}
		case `${BANK_UPDATE}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isLoadingData: false,
				isError: true,
				data: null,
			}
		case `${BANK_UPDATE}_CLEAR`:
			return {
				...state,
				isLoading: false,
				isError: false,
				alert: false,
			}
		case `${BANK_UPDATE}_RESET`:
			return initialState
		default:
			return state
	}
}
