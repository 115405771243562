import { createTheme } from '@material-ui/core/styles'
import 'source-sans-pro/source-sans-pro.css'
import 'typeface-oxygen/index.css'

const theme = createTheme({
	palette: {
		type: 'light',
		common: {
			black: '#000',
			white: '#fff',
		},
		background: {
			paper: '#fff',
			default: '#fff',
			surface1: '#eee',
			surface2: '#cce5ff',
			dashboard: '#e7eaf2',
		},
		primary: {
			light: '#1D406E',
			main: '#081E3F',
			dark: '#020F23',
			contrastText: '#fff',
		},
		secondary: {
			light: '#009EFF',
			main: '#007BFF',
			dark: '#1F47CD',
			contrastText: '#fff',
		},
		warning: {
			light: '#fff3cd',
			main: '#fff3cd',
			dark: '#ffe47f',
			contrastText: '#856404',
		},
		alert: {
			light: '#F8D7DA',
			main: '#F8D7DA',
			dark: '#ca606c',
			contrastText: '#343A40',
		},
		error: {
			light: '#FD464E',
			main: '#DC3545',
			dark: '#C02333',
			contrastText: '#fff',
		},
		success: {
			light: '#ceead5',
			main: '#28A745',
			dark: '#0a842f',
			contrastText: '#fff',
		},
		info: {
			light: '#e1f5fe',
			main: '#03a9f4',
			dark: '#0288d1',
			contrastText: '#fff',
		},
		text: {
			primary: '#343A40',
			secondary: '#fff',
			disabled: 'rgba(0, 0, 0, 0.38)',
			hint: '#6C757E',
		},
		grey: {
			50: '#f5f5f5',
			100: '#eeeeee',
			200: '#e0e0e0',
			300: '#ced4da',
			400: '#bdbdbd',
			500: '#9e9e9e',
			600: '#757575',
			700: '#616161',
			800: '#424242',
			900: '#212121',
			A100: '#d5d5d5',
			A200: '#aaaaaa',
			A400: '#303030',
			A700: '#616161',
		},
		status: {
			darkBlue: '#0c3169',
			darkerBlue: '#081E3F',
			blue: '#007bff',
			lightBlue: '#A5C7EA',
			yellow: '#ffc10a',
			green: '#28a745',
			orange: '#ed7d0c',
			lightOrange: '#FF9300',
			red: '#dc3545',
			lightRed: '#ff0018',
			grey: '#6c757e',
			text1: '#fff',
			text2: '#343A40',
		},
		border: {
			size: '1px',
			style: 'solid',
			color: 'rgba(224, 224, 224, 1)',
			complete: '1px solid rgba(224, 224, 224, 1)',
		},
	},
	typography: {
		fontFamily: ['Source Sans Pro', 'Oxygen', 'sans serif'].join(','),
		htmlFontSize: 15,
		fontSize: 15,
		h1: {
			fontFamily: ['Oxygen', 'sans serif'].join(','),
			fontWeight: 700,
			fontSize: 96,
		},
		h2: {
			fontFamily: ['Oxygen', 'sans serif'].join(','),
			fontWeight: 700,
			fontSize: 60,
		},
		h3: {
			fontFamily: ['Oxygen', 'sans serif'].join(','),
			fontWeight: 400,
			fontSize: 48,
		},
		h4: {
			fontFamily: ['Oxygen', 'sans serif'].join(','),
			fontWeight: 400,
			fontSize: 34,
		},
		h5: {
			fontFamily: ['Oxygen', 'sans serif'].join(','),
			fontWeight: 700,
			fontSize: 24,
		},
		h6: {
			fontFamily: ['Oxygen', 'sans serif'].join(','),
			fontWeight: 700,
			fontSize: 20,
		},
		subtitle1: {
			fontWeight: 700,
			fontSize: 16,
		},
		subtitle2: {
			fontWeight: 700,
			fontSize: 14,
		},
		body1: {
			fontWeight: 400,
			fontSize: 16,
		},
		body2: {
			fontWeight: 400,
			fontSize: 14,
		},
		body3: {
			fontWeight: 400,
			fontSize: 12,
		},
		caption: {
			fontWeight: 500,
			fontSize: 12,
			// fontStyle: 'italic',
		},
		overline: {
			fontWeight: 400,
			fontSize: 10,
		},
	},
})

const { breakpoints } = theme

const ot = {
	...theme,
	overrides: {
		MuiTypography: {
			[breakpoints.down('xs')]: {
				htmlFontSize: 5,
			},
			h1: {
				[breakpoints.down('xs')]: {
					fontSize: '2rem',
				},
			},
			h2: {
				[breakpoints.down('xs')]: {
					fontSize: '1.8rem',
				},
			},
			h3: {
				[breakpoints.down('xs')]: {
					fontSize: '1.6rem',
				},
			},
			h4: {
				[breakpoints.down('xs')]: {
					fontSize: '1.6rem',
				},
			},
			h5: {
				[breakpoints.down('xs')]: {
					fontSize: '1.4rem',
				},
			},
			h6: {
				[breakpoints.down('xs')]: {
					fontSize: '1.2rem',
				},
			},
		},
		MuiInputLabel: {
			root: {
				color: theme.palette.text.primary,
			},
		},
		MuiSelect: {
			outlined: {
				padding: 10,
				fontWeight: 400,
			},
			select: {
				fontSize: '1rem',
			},
			disabled: {
				backgroundColor: theme.palette.grey[100],
			},
		},
		MuiMenuItem: {
			root: {
				fontWeight: 400,
			},
			selected: {
				fontSize: '1rem',
			},
		},
		MuiButton: {
			label: {
				textTransform: 'none',
			},
		},
		MuiInputBase: {
			input: {
				backgroundColor: theme.palette.background.default,
				borderRadius: theme.shape.borderRadius,
				paddingLeft: theme.spacing(2.25),
				border: 'none',
			},
		},
		MuiInput: {
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&&:after': {
					borderBottom: 'none',
				},
			},
		},
		MuiLink: {
			root: {
				cursor: 'pointer',
			},
		},
		MuiPaginationItem: {
			selected: {
				backgroundColor: theme.palette.secondary.main,
				color: theme.palette.text.secondary,
			},
			disabled: {
				backgroundColor: theme.palette.grey[400],
				color: theme.palette.text.primary,
			},
			textSecondary: {
				borderRadius: theme.shape.borderRadius,
				backgroundColor: theme.palette.text.secondary,
				color: theme.palette.text.primary,
			},
		},
		MuiCollapse: {
			hidden: {
				padding: '0px !important',
				margin: '0px !important',
			},
		},
		MuiStepContent: {
			root: {
				marginTop: theme.spacing(0),
			},
		},
		MuiStepConnector: {
			vertical: {
				paddingBottom: theme.spacing(0),
			},
		},
		MuiTab: {
			wrapper: {
				fontFamily: ['Oxygen', 'sans serif'].join(','),
			},
		},
	},
}

export default ot
