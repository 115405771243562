import { PROMO_PAGES } from '../../types'

const initialState = {
	isLoading: false,
	isError: false,
	data: null,
	error: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${PROMO_PAGES}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${PROMO_PAGES}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${PROMO_PAGES}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
				error: action.payload,
			}
		case `${PROMO_PAGES}_RESET`:
			return initialState
		default:
			return state
	}
}
