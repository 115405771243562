import { FILTER_ITEM_RFQ, SUBMIT_RFQ } from '../../../types'

const initialValue = {
	filteredItems: {
		data: [],
	},
	isLoading: false,
	data: null,
	isError: false,
}

export default (state = initialValue, action) => {
	switch (action.type) {
		case `${FILTER_ITEM_RFQ}_FULFILLED`:
			return {
				...state,
				filteredItems: action.payload,
			}
		case `${SUBMIT_RFQ}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${SUBMIT_RFQ}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${SUBMIT_RFQ}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
			}
		default:
			return state
	}
}
