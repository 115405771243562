import { INVOICE_DETAIL } from '../../../types'

const initialState = {
	isLoading: true,
	isError: false,
	data: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${INVOICE_DETAIL}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${INVOICE_DETAIL}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${INVOICE_DETAIL}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
			}
		case `${INVOICE_DETAIL}_RESET`:
			return initialState
		default:
			return state
	}
}
