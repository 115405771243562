import { REGISTER } from '../types'

const initialValue = {
	isLoading: false,
	isError: false,
	data: null,
	error: null,
}

export default (state = initialValue, action) => {
	switch (action.type) {
		case `${REGISTER}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${REGISTER}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
			}
		case `${REGISTER}_FAILED`:
			return {
				...state,
				isLoading: false,
				isError: true,
				err: action.err.message,
			}
		default:
			return {
				...state,
			}
	}
}
