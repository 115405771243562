/* eslint-disable no-undef */
import {
	createStore as reduxCreateStore,
	applyMiddleware,
	compose,
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers'
import middlewares from './middlewares'
import { persistStore } from 'redux-persist'

// const persistedReducer = persistReducer(persistConfig, rootReducer)
const createStore = (preloadedState) => {
	return process.env.NODE_ENV === 'development'
		? reduxCreateStore(
				rootReducer,
				composeWithDevTools(applyMiddleware(...middlewares))
		  )
		: reduxCreateStore(
				rootReducer,
				preloadedState,
				compose(applyMiddleware(...middlewares))
		  )
}
export const store = createStore()
export const persistor = persistStore(store)
