import { APPROVAL_CATEGORY_CREATE } from '../../../types'

const initialState = {
	isLoading: false,
	isError: false,
	data: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${APPROVAL_CATEGORY_CREATE}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${APPROVAL_CATEGORY_CREATE}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${APPROVAL_CATEGORY_CREATE}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
				error: action.payload.error,
			}
		case `${APPROVAL_CATEGORY_CREATE}_RESET`:
			return initialState
		default:
			return initialState
	}
}
