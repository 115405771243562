import { GET_DETAIL_CATEGORY_SLUG } from '../../types'
const initialState = {
	data: null,
	isLoading: true,
	isError: false,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${GET_DETAIL_CATEGORY_SLUG}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${GET_DETAIL_CATEGORY_SLUG}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
			}
		case `${GET_DETAIL_CATEGORY_SLUG}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
			}
		default:
			return state
	}
}
