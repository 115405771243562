import { LOGIN, LOGOUT, SET_REDIRECT } from '../types'
import moment from 'moment'
import { encryptAES } from '../../utils/cryptojs'

const initialValue = {
	isLoading: false,
	isError: false,
	loggedIn: false,
	data: null,
	loginExpire: null,
	error: null,
	redirect: null,
}

export default (state = initialValue, action) => {
	switch (action.type) {
		case `${LOGIN}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${LOGIN}_FULFILLED`:
			// encrypt user roles data
			if (!action.update || (action.update && action.update == false)) {
				var ciphertext = encryptAES(action.payload.data.data.user_roles)
				action.payload.data.data.user_roles = ciphertext
			}

			return {
				...state,
				isLoading: false,
				data: action.payload.data,
				loggedIn: true,
				loginExpire: moment().add(23, 'hours'),
			}
		case `${LOGIN}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
				err: action.errResponse,
			}
		case `${LOGIN}_RESET`:
			return {
				...state,
				isLoading: false,
				isError: false,
			}
		case `${LOGOUT}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${LOGOUT}_FULFILLED`:
			return {
				...initialValue,
			}
		case `${LOGOUT}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
				err: action.err,
			}
		case `${SET_REDIRECT}`:
			return {
				...state,
				redirect: action.payload,
			}
		default:
			return {
				...state,
			}
	}
}
