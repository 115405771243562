import { SHIPPING_TRACKING } from '../../../types'

const initialState = {
	isLoading: true,
	isError: false,
	data: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${SHIPPING_TRACKING}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${SHIPPING_TRACKING}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${SHIPPING_TRACKING}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
			}
		default:
			return state
	}
}
