import { CONTACT_US } from '../../types'

const initialState = {
	isLoading: false,
	isError: false,
	data: null,
	error: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${CONTACT_US}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${CONTACT_US}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${CONTACT_US}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
				error: action.payload.err,
			}
		case `${CONTACT_US}_RESET`:
			return initialState
		default:
			return initialState
	}
}
