import { DEPARTMENT_CREATE } from '../../../types'

const initialState = {
	isLoading: false,
	isError: false,
	alert: false,
	data: [],
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${DEPARTMENT_CREATE}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
				data: [],
			}
		case `${DEPARTMENT_CREATE}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
				alert: true,
			}
		case `${DEPARTMENT_CREATE}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
				data: [],
			}
		case `${DEPARTMENT_CREATE}_CLEAR`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: [],
				alert: false,
			}
		default:
			return state
	}
}
