import { USERS_ACCOUNT_CREATE } from '../../types'

const initialState = {
	isLoading: false,
	isError: false,
	data: { message: '' },
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${USERS_ACCOUNT_CREATE}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${USERS_ACCOUNT_CREATE}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${USERS_ACCOUNT_CREATE}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
				error: action.payload.error,
			}
		case `${USERS_ACCOUNT_CREATE}_RESET`:
			return initialState
		default:
			return initialState
	}
}
