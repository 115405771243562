import CryptoJS from 'crypto-js'

export const encryptAES = (text) => {
	let ciphertext = CryptoJS.AES.encrypt(text, 'mbiz-secret-321').toString()
	return ciphertext
}

export const decryptAES = (text) => {
	let decrypt = CryptoJS.AES.decrypt(text, 'mbiz-secret-321')
	let originalText = decrypt.toString(CryptoJS.enc.Utf8)
	return originalText
}
