import { persistReducer } from 'redux-persist'
import { combineReducers } from 'redux'
import { authConfig, mouItemsConfig } from '../persistConfig'
import catalogReducer from './catalog/catalogReducer'
import cartReducer from './cartReducer'
import authReducer from './authReducer'
import usersReducer from './usersReducer'
import detailProductReducer from './detailProductReducer'
import listFilterCategoryReducer from './Category/listFilterCategoryReducer'
import catalogSuggestionReducer from './catalog/catalogSuggestionReducer'
import locationReducer from './locationReducer'
import RFQListReducer from './Transactions/RFQ/RFQListReducer'
import companyAddressReducer from './companyAddressReducer'
import detailRfqReducer from './Transactions/RFQ/detailRfqReducer'
import detailQuotationReducer from './Transactions/Quotations/detailQuotationReducer'
import quotationListReducer from './Transactions/Quotations/quotationListReducer'
import detailPurchaseOrderReducer from './Transactions/PurchaseOrder/detailReducer'
import POListReducer from './Transactions/PurchaseOrder/POListReducer'
import trackingReducer from './Transactions/PurchaseOrder/trackingReducer'
import submitRfqReducer from './Transactions/RFQ/submitRfqReducer'
import invoiceListReducer from './Transactions/Invoice/listReducer'
import invoiceDetailReducer from './Transactions/Invoice/detailReducer'
import UsersAccountListReducer from './UsersAccount/listReducer'
import UsersAccountDetailReducer from './UsersAccount/detailReducer'
import UsersAccountCreateReducer from './UsersAccount/createReducer'
import UsersAccountUpdateReducer from './UsersAccount/updateReducer'
import UsersAccountUpdateNewUserReducer from './UsersAccount/updateNewUserReducer'
import createPoReducer from './Transactions/Quotations/createPoReducer'
import confirmShippingReducer from './Transactions/PurchaseOrder/confirmShippingReducer'
import paymentListReducer from './Transactions/Payment/listReducer'
import paymentDetailReducer from './Transactions/Payment/detailReducer'
import { CLEAR_STATE } from '../types'
import bankAaccountReducer from './bankAccountReducer'
import confirmReducer from './Transactions/Payment/confirmReducer'
import companyProfileReducer from './CompanyProfile/detail'
import companyProfileUpdateReducer from './CompanyProfile/update'
import forgotPasswordReducer from './UsersAccount/forgotPasswordReducer'
import resetPasswordReducer from './UsersAccount/resetPasswordReducer'
import departmentListReducer from './Company/Department/listReducer'
import departmentCreateReducer from './Company/Department/createReducer'
import departmentDeleteReducer from './Company/Department/deleteReducer'
import departmentUpdateReducer from './Company/Department/updateReducer'
// approval category
import ACListReducer from './Approval/Category/ACListReducer'
import ACDetailReducer from './Approval/Category/ACDetailReducer'
import ACCreateReducer from './Approval/Category/ACCreateReducer'
import ACUpdateReducer from './Approval/Category/ACUpdateReducer'
import ACDeleteReducer from './Approval/Category/ACDeleteReducer'
// approval eproc
import AEListReducer from './Approval/Eproc/AEListReducer'
import AEDetailReducer from './Approval/Eproc/AEDetailReducer'
import AECreateReducer from './Approval/Eproc/AECreateReducer'
import AEUpdateReducer from './Approval/Eproc/AEUpdateReducer'
import AEDeleteReducer from './Approval/Eproc/AEDeleteReducer'
// approval department
import ADListReducer from './Approval/Department/ADListReducer'
import ADDetailReducer from './Approval/Department/ADDetailReducer'
import ADCreateReducer from './Approval/Department/ADCreateReducer'
import ADUpdateReducer from './Approval/Department/ADUpdateReducer'
import ADDeleteReducer from './Approval/Department/ADDeleteReducer'

import ACActivateReducer from './Approval/Configure/ACActivateReducer'
import ACInactivateReducer from './Approval/Configure/ACInactivateReducer'

import bulkPaymentReducer from './Transactions/Payment/bulkReducer'
import masterReducer from './Master'
import categoryRefReducer from './Category/categoryRefReducer'

// pr
import PuchaseRequestDetailReducer from './Transactions/PurchaseRequest/detailReducer'
import PuchaseRequestListReducer from './Transactions/PurchaseRequest/listReducer'
import PurchaseRequestCreateReducer from './Transactions/PurchaseRequest/createPrReducer'

//ContactUs
import ContactUsReducer from './ContactUs/contactUsReducer'

//Billing Address
import addressList from './Address/Address/listReducer'
import addressDelete from './Address/Address/deleteReducer'
import addressCreate from './Address/Address/createReducer'
import addressUpdate from './Address/Address/updateReducer'

//Billing Address
import billingList from './Address/Billing/listReducer'
import billingDelete from './Address/Billing/deleteReducer'
import billingCreate from './Address/Billing/createReducer'
import billingUpdate from './Address/Billing/updateReducer'

//Shipping Address
import shippingList from './Address/Shipping/listReducer'
import shippingDelete from './Address/Shipping/deleteReducer'
import shippingCreate from './Address/Shipping/createReducer'
import shippingUpdate from './Address/Shipping/updateReducer'

//Bank
import bankList from './Bank/listReducer'
import bankDelete from './Bank/deleteReducer'
import bankUpdate from './Bank/updateReducer'
import bankNew from './Bank/createReducer'

import userProfileReducer from './UsersAccount/userProfileReducer'

//MOU
import MOUListReducer from './MOU/MOUListReducer'
import detailMouReducer from './Transactions/MOU/detail'
import mouItemsReducer from './MOU/items'
import transactionLogMouReducer from './MOU/transactionLog'
import MOUDeliveryReducer from './MOU/MOUDelivery'
import MOUSubmitPOReducer from './MOU/MOUSubmitPO'

import reportFile from './Transactions/Report/downloadFileReducer'

//Timeline
import timelineReducer from './Transactions/Timeline/timelineReducer'

// global reducer
import globalReducer from './globalReducer'

//promo pages
import promoPages from './Promo/promoReducer'
import categorySlugReducer from './Category/categorySlugReducer'

// budget
import budgetListReducer from './Budget/listReducer'
import budgetDetailReducer from './Budget/detailReducer'
import budgetHistoryReducer from './Budget/historyReducer'
import budgetListValidReducer from './Budget/listValidReducer'
import detailPOBudgetReducer from './Transactions/PurchaseOrder/detailBudgetReducer'

const appReducer = combineReducers({
	catalogReducer,
	cart: cartReducer,
	auth: persistReducer(authConfig, authReducer),
	usersReducer,
	detailProductReducer,
	listFilterCategoryReducer,
	catalogSuggestionReducer,
	locationReducer,
	RFQListReducer,
	companyAddressReducer,
	detailRfq: detailRfqReducer,
	detailQuotation: detailQuotationReducer,
	quotationListReducer,
	detailPO: detailPurchaseOrderReducer,
	POListReducer,
	trackingReducer,
	submitRfq: submitRfqReducer,
	invoiceListReducer,
	invoiceDetailReducer,
	UsersAccountListReducer,
	UsersAccountDetailReducer,
	UsersAccountCreateReducer,
	UsersAccountUpdateReducer,
	UsersAccountUpdateNewUserReducer,
	createPo: createPoReducer,
	confirmShipping: confirmShippingReducer,
	paymentListReducer,
	paymentDetailReducer,
	bankAccount: bankAaccountReducer,
	paymentConfirm: confirmReducer,
	companyProfile: companyProfileReducer,
	companyProfileUpdate: companyProfileUpdateReducer,
	forgotPasswordReducer: forgotPasswordReducer,
	resetPasswordReducer: resetPasswordReducer,
	departmentListReducer,
	departmentCreateReducer,
	departmentDeleteReducer,
	departmentUpdateReducer,
	// approval category
	ACListReducer,
	ACDetailReducer,
	ACCreateReducer,
	ACUpdateReducer,
	ACDeleteReducer,

	// approval eproc
	AEListReducer,
	AEDetailReducer,
	AECreateReducer,
	AEUpdateReducer,
	AEDeleteReducer,

	// approval department
	ADListReducer,
	ADDetailReducer,
	ADCreateReducer,
	ADUpdateReducer,
	ADDeleteReducer,

	ACActivateReducer,
	ACInactivateReducer,

	bulkPayment: bulkPaymentReducer,
	location: masterReducer,
	categoryRefReducer,
	PRDetail: PuchaseRequestDetailReducer,
	PRList: PuchaseRequestListReducer,
	PRCreate: PurchaseRequestCreateReducer,

	ContactUsReducer,

	// Address
	addressList,
	addressDelete,
	addressCreate,
	addressUpdate,
	
	// Billing Address
	billingList,
	billingDelete,
	billingCreate,
	billingUpdate,

	// Shipping Address
	shippingList,
	shippingDelete,
	shippingCreate,
	shippingUpdate,

	//Bank
	bankList,
	bankDelete,
	bankUpdate,
	bankNew,

	//userProfileReducer
	userProfileReducer,

	//MOU
	MOUListReducer,
	detailMou: detailMouReducer,
	mouItems: persistReducer(mouItemsConfig, mouItemsReducer),
	transactionLogMou: transactionLogMouReducer,
	MOUDeliveryReducer,
	MOUSubmitPOReducer,
	reportFile,

	//timeline
	timelineReducer,

	globalReducer,

	//promo pages
	promoPages,
	categorySlug: categorySlugReducer,

	// budget
	budgetListReducer,
	budgetDetailReducer,
	budgetHistoryReducer,
	budgetListValidReducer,
	detailPOBudget: detailPOBudgetReducer,
})

const rootReducer = (state, action) => {
	if (action.type === CLEAR_STATE) {
		state.auth = undefined
		state.cart = undefined
		window.location.href = '/login'
	}
	return appReducer(state, action)
}

export default rootReducer
