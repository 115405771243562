import { DETAIL_PRODUCT, DETAIL_PRICE } from '../types'

const initialState = {
	isLoading: true,
	isError: false,
	data: null,
	price: null,
	loadPrice: true,
	errorPrice: false,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${DETAIL_PRODUCT}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${DETAIL_PRODUCT}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${DETAIL_PRODUCT}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
			}
		case `${DETAIL_PRODUCT}_RESET`:
			return initialState
		case `${DETAIL_PRICE}_PENDING`: {
			return {
				...state,
				loadPrice: true,
				errorPrice: false,
			}
		}
		case `${DETAIL_PRICE}_FULFILLED`: {
			return {
				...state,
				loadPrice: false,
				errorPrice: false,
				price: action.payload.data,
			}
		}
		case `${DETAIL_PRICE}_REJECTED`: {
			return {
				...state,
				loadPrice: false,
				errorPrice: true,
			}
		}
		default:
			return state
	}
}
