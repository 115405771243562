import {
	ADD_TO_CART,
	LIST_CART_ITEM,
	COUNT_CART_ITEM,
	SET_MODAL,
	UPDATE_CART_ITEM,
	SAVE_CART_ID,
	DELETE_ALL_CART_ITEMS,
} from '../types'
import { persistReducer } from 'redux-persist'
import { cartConfig, listItemCartConfig } from '../persistConfig'
import { combineReducers } from 'redux'

const initialValue = {
	listItem: {
		filteredItems: [],
		cartQty: 0,
	},
	addCart: {
		isLoading: false,
		isError: false,
		items: [],
		showModal: false,
	},
	saveCart: {
		isLoading: true,
		isError: false,
		items: [],
	},
	updateCart: {
		isLoading: true,
		isError: false,
		filteredItems: [],
		cartQty: 0,
	},
	countCart: {
		isLoading: true,
		isError: false,
		cartQty: 0,
	},
	cartPrice: {
		isLoading: true,
		price: null,
		isError: false,
	},
}

const addtoCartReducer = (state = initialValue.addCart, action) => {
	switch (action.type) {
		case `${ADD_TO_CART}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${ADD_TO_CART}_FULFILLED`:
			return {
				...state,
				items: action.payload.data,
				isLoading: false,
				isError: false,
			}
		case `${ADD_TO_CART}_REJECTED`:
			return {
				...state,
				isError: true,
				isLoading: false,
			}
		case `${SET_MODAL}`:
			return {
				...state,
				showModal: action.payload,
			}
		default:
			return state
	}
}

export const listCartItemReducer = (state = initialValue.listItem, action) => {
	switch (action.type) {
		case `${LIST_CART_ITEM}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${LIST_CART_ITEM}_FULFILLED`:
			return {
				...state,
				filteredItems: action.payload.data,
				isLoading: false,
				isError: false,
				cartQty: action.payload.data.length,
			}
		case `${LIST_CART_ITEM}_REJECTED`:
			return {
				...state,
				isError: true,
				isLoading: false,
			}
		case 'RESET_LIST_CART_ITEM':
			return {
				filteredItems: [],
			}
		default:
			return state
	}
}

const saveCartItemReducer = (state = initialValue.saveCart, action) => {
	switch (action.type) {
		case `${SAVE_CART_ID}`:
			return {
				...state,
				isLoading: false,
				items: action.payload.item,
			}
		default:
			return state
	}
}

const updateCartItem = (state = initialValue.updateCart, action) => {
	switch (action.type) {
		case `${UPDATE_CART_ITEM}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${UPDATE_CART_ITEM}_FULFILLED`:
			return {
				...state,
				filteredItems: action.payload.data,
				isLoading: false,
				isError: false,
				cartQty: action.payload.data.length,
			}
		case `${UPDATE_CART_ITEM}_REJECTED`:
			return {
				...state,
				isError: true,
				isLoading: false,
			}
		default:
			return state
	}
}

const countCartItemReducer = (state = initialValue.countCart, action) => {
	switch (action.type) {
		case `${COUNT_CART_ITEM}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${COUNT_CART_ITEM}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				cartQty: action.payload.data.total,
			}
		case `${COUNT_CART_ITEM}_REJECTED`:
			return {
				...state,
				isError: true,
				isLoading: false,
				cartQty: 0,
			}
		default:
			return state
	}
}

const deleteAllCartItemsReducer = (state = initialValue.countCart, action) => {
	if (action.type == `${DELETE_ALL_CART_ITEMS}_FULFILLED`) {
		return {
			...state,
			isLoading: false,
			isError: false,
			success: true,
		}
	}
	return state
}

export default combineReducers({
	countCart: countCartItemReducer,
	updateCart: updateCartItem,
	listCart: persistReducer(listItemCartConfig, listCartItemReducer),
	addCart: addtoCartReducer,
	savedCart: persistReducer(cartConfig, saveCartItemReducer),
	deleteAll: deleteAllCartItemsReducer,
	// priceCart: cartPriceReducer,
})
