import { PAYMENT_LIST } from '../../../types'

const initialState = {
	isLoading: true,
	isError: false,
	data: [],
}

export default (state = initialState, action) => {
	// console.log("state", state, "action", action)
	switch (action.type) {
		case `${PAYMENT_LIST}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${PAYMENT_LIST}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${PAYMENT_LIST}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
			}
		default:
			return state
	}
}
