import { CATALOG, CATALOG_NEW_PRICE } from '../../types'
import { combineReducers } from 'redux'
const initialState = {
	data: [],
	isLoading: true,
	isError: false,
}

const catalogReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${CATALOG}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${CATALOG}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
			}
		case `${CATALOG}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
			}
		default:
			return state
	}
}

const catalogPriceReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${CATALOG_NEW_PRICE}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${CATALOG_NEW_PRICE}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				data: action.payload.data ? [...state.data, action.payload.data] : [],
			}
		case `${CATALOG_NEW_PRICE}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
			}
		default:
			return state
	}
}

export default combineReducers({
	catalog: catalogReducer,
	catalogPrice: catalogPriceReducer,
})
