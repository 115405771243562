import { USERS_ACCOUNT_LIST } from '../../types'

const initialState = {
	isLoading: false,
	isError: false,
	data: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${USERS_ACCOUNT_LIST}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${USERS_ACCOUNT_LIST}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${USERS_ACCOUNT_LIST}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
			}
		default:
			return state
	}
}
