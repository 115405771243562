import { COMPANY_PROFILE, COMPANY_CONTACTS } from '../../types'
import { combineReducers } from 'redux'

const initialState = {
	isLoading: true,
	isError: false,
	data: null,
}

const company = (state = initialState, action) => {
	switch (action.type) {
		case `${COMPANY_PROFILE}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${COMPANY_PROFILE}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${COMPANY_PROFILE}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
			}
		case `${COMPANY_PROFILE}_RESET`:
			return initialState
		default:
			return state
	}
}

const contacts = (state = initialState, action) => {
	switch (action.type) {
		case `${COMPANY_CONTACTS}_PENDING`:
			return {
				isLoading: true,
				isError: false,
			}
		case `${COMPANY_CONTACTS}_FULFILLED`:
			return {
				isError: false,
				isLoading: false,
				data: action.payload.data,
			}
		case `${COMPANY_CONTACTS}_REJECTED`:
			return {
				isError: true,
				err: action.payload,
			}
		case `${COMPANY_CONTACTS}_RESET`:
			return initialState
		default:
			return state
	}
}

export default combineReducers({
	company,
	contacts,
})
