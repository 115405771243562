import { CATALOG_SUGGESTION } from '../../types'

const initialState = {
	isLoading: true,
	isError: false,
	data: { category: [] },
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${CATALOG_SUGGESTION}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${CATALOG_SUGGESTION}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${CATALOG_SUGGESTION}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
				data: action.payload.data,
			}
		case `${CATALOG_SUGGESTION}_RESET`:
			return initialState
		default:
			return state
	}
}
