import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import {
	createBlacklistFilter,
	createWhitelistFilter,
} from 'redux-persist-transform-filter'

const filterAuthState = createBlacklistFilter('auth', ['isLoading', 'isError'])

const filterCartState = createWhitelistFilter('cart.savedCart', ['items'])
const filterListItemCart = createWhitelistFilter('cart.listCart', [
	'filteredItems',
])

export const cartConfig = {
	key: 'cart',
	storage,
	stateReconciler: hardSet,
	transform: [filterCartState],
}

export const listItemCartConfig = {
	key: 'listCart',
	storage,
	stateReconciler: hardSet,
	transform: [filterListItemCart],
}

export const authConfig = {
	key: 'auth',
	storage,
	stateReconciler: hardSet,
	transform: [filterAuthState],
}

export const mouItemsConfig = {
	key: 'mou',
	storage,
	stateReconciler: hardSet,
}

const persistConfig = {
	key: 'root',
	storage,
	version: 1,
	whitelist: ['listItemsCart'],
	transforms: [filterListItemCart],
}

export default persistConfig
