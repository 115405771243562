import { GET_ALL_COUNTRY, GET_REGION, GET_ALL_STATE } from '../../types'
import { combineReducers } from 'redux'

const initialState = {
	isLoading: false,
	isError: false,
	data: [],
}

const countriesReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${GET_ALL_COUNTRY}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${GET_ALL_COUNTRY}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${GET_ALL_COUNTRY}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
			}
		default:
			return state
	}
}

const stateReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${GET_ALL_STATE}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${GET_ALL_STATE}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${GET_ALL_STATE}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
			}
		default:
			return state
	}
}

const kecamatanReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${GET_REGION}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${GET_REGION}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${GET_REGION}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
			}
		default:
			return state
	}
}

const locationReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${GET_REGION}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${GET_REGION}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${GET_REGION}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
			}
		case `${GET_REGION}_RESET`:
			return initialState
		default:
			return state
	}
}

export default combineReducers({
	countries: countriesReducer,
	province: stateReducer,
	kecamatan: kecamatanReducer,
	location: locationReducer,
})
