import { BILLING_ADDRESS, SHIPPING_ADDRESS, SET_DEFAULT } from '../types'
import { combineReducers } from 'redux'
const initialValue = {
	isLoading: false,
	isError: false,
	data: null,
	defaultAddress: null,
	receiverPhone: '-',
}

const billingAddressReducer = (state = initialValue, action) => {
	switch (action.type) {
		case `${BILLING_ADDRESS}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${BILLING_ADDRESS}_FULFILLED`:
			return {
				...state,
				data: action.payload.data,
				isLoading: false,
				isError: false,
			}
		case `${BILLING_ADDRESS}_REJECTED`:
			return {
				...state,
				isError: true,
			}
		case `${SET_DEFAULT}_billing`:
			return {
				...state,
				defaultAddress: action.payload.data,
			}
		default:
			return { ...state }
	}
}

const shippingAddressReducer = (state = initialValue, action) => {
	switch (action.type) {
		case `${SHIPPING_ADDRESS}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${SHIPPING_ADDRESS}_FULFILLED`:
			return {
				...state,
				data: action.payload.data,
				isLoading: false,
				isError: false,
			}
		case `${SHIPPING_ADDRESS}_REJECTED`:
			return {
				...state,
				isError: true,
			}
		case `${SET_DEFAULT}_shipping`:
			return {
				...state,
				defaultAddress: action.payload.data,
			}
		case 'SET_RECEIVER_PHONE':
			return {
				...state,
				receiverPhone: action.payload.data,
			}
		default:
			return { ...state }
	}
}

export default combineReducers({
	billingAddressReducer,
	shippingAddressReducer,
})
