export const CATALOG = 'CATALOG'
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const CLEAR_CART = 'CLEAR_CART'
export const DELETE_ALL_CART_ITEMS = 'DELETE_ALL_CART_ITEMS'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const CLEAR_STATE = 'CLEAR_STATE'
export const SET_REDIRECT = 'SET_REDIRECT'
export const DETAIL_PRODUCT = 'DETAIL_PRODUCT'
export const CATALOG_SUGGESTION = 'CATALOG_SUGGESTION'
export const REGISTER = 'REGISTER'
export const REGISTER_NEW = 'REGISTER_NEW'
export const PROVINCES = 'PROVICES'
export const CITIES = 'CITIES'
export const RFQ_LIST = 'RFQ_LIST'
export const BILLING_ADDRESS = 'BILLING_ADDRESS'
export const SHIPPING_ADDRESS = 'SHIPPING_ADDRESS'
export const SET_DEFAULT = 'SET_DEFAULT'
export const LIST_CART_ITEM = 'LIST_CART_ITEM'
export const RESET_LIST_CART_ITEM = 'RESET_LIST_CART_ITEM'
export const FILTER_CART_ITEM = 'FILTER_CART_ITEM'
export const SUBMIT_RFQ = 'SUBMIT_RFQ'
export const DETAIL_RFQ = 'DETAIL_RFQ'
export const COUNT_CART_ITEM = 'COUNT_CART_ITEM'
export const SET_MODAL = 'SET_MODAL'
export const CATALOG_NEW_PRICE = 'CATALOG_NEW_PRICE'
export const QUOTATION_LIST = 'QUOTATION_LIST'
export const DETAIL_QUOTATION = 'DETAIL_QUOTATION'
export const PURCHASE_ORDER_LIST = 'PURCHASE_ORDER_LIST'
export const DETAIL_PURCHASE_ORDER = 'DETAIL_PURCHASE_ORDER'
export const DETAIL_PURCHASE_ORDER_BUDGET = 'DETAIL_PURCHASE_ORDER_BUDGET'
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM'
export const SAVE_CART_ID = 'SAVE_CART_ID'
export const FILTER_ITEM_RFQ = 'FILTER_ITEM_RFQ'
export const INVOICE_LIST = 'INVOICE_LIST'
export const INVOICE_DETAIL = 'INVOICE_DETAIL'
export const CONFIRM_SHIPPING = 'CONFIRM_SHIPPING'
export const USERS_ACCOUNT_LIST = 'USERS_ACCOUNT_LIST'
export const USERS_ACCOUNT_CREATE = 'USERS_ACCOUNT_CREATE'
export const USERS_ACCOUNT_UPDATE = 'USERS_ACCOUNT_UPDATE'
export const USERS_ACCOUNT_UPDATE_NEW_USER = 'USERS_ACCOUNT_UPDATE_NEW_USER'
export const USERS_ACCOUNT_DETAIL = 'USERS_ACCOUNT_DETAIL'
export const CREATE_PO = 'CREATE_PO'
export const BANK_ACCOUNT = 'BANK_ACCOUNT'
export const PAYMENT_LIST = 'PAYMENT_LIST'
export const PAYMENT_DETAIL = 'PAYMENT_DETAIL'
export const CONFIRM_PAYMENT = 'CONFIRM_PAYMENT'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_USER_DETAIL = 'RESET_PASSWORD_USER_DETAIL'
export const DEPARTMENT_LIST = 'DEPARTMENT_LIST'
export const DEPARTMENT_DETAIL = 'DEPARTMENT_DETAIL'
export const DEPARTMENT_CREATE = 'DEPARTMENT_CREATE'
export const DEPARTMENT_UPDATE = 'DEPARTMENT_UPDATE'
export const DEPARTMENT_DELETE = 'DEPARTMENT_DELETE'
export const SEARCH_INVOICE = 'SEARCH_INVOICE'
export const REMOVE_INVOICE = 'REMOVE_INVOICE'
export const SELECT_INVOICE = 'SELECT_INVOICE'
export const CONFIRM_BULK_PAYMENT = 'CONFIRM_BULK_PAYMENT'

export const APPROVAL_CATEGORY_LIST = 'APPROVAL_CATEGORY_LIST'
export const APPROVAL_CATEGORY_CREATE = 'APPROVAL_CATEGORY_CREATE'
export const APPROVAL_CATEGORY_UPDATE = 'APPROVAL_CATEGORY_UPDATE'
export const APPROVAL_CATEGORY_DELETE = 'APPROVAL_CATEGORY_UPDATE'
export const APPROVAL_CATEGORY_DETAIL = 'APPROVAL_CATEGORY_DETAIL'

export const APPROVAL_EPROC_LIST = 'APPROVAL_EPROC_LIST'
export const APPROVAL_EPROC_CREATE = 'APPROVAL_EPROC_CREATE'
export const APPROVAL_EPROC_UPDATE = 'APPROVAL_EPROC_UPDATE'
export const APPROVAL_EPROC_DELETE = 'APPROVAL_EPROC_DELETE'
export const APPROVAL_EPROC_DETAIL = 'APPROVAL_EPROC_DETAIL'

export const APPROVAL_DEPARTMENT_LIST = 'APPROVAL_DEPARTMENT_LIST'
export const APPROVAL_DEPARTMENT_CREATE = 'APPROVAL_DEPARTMENT_CREATE'
export const APPROVAL_DEPARTMENT_UPDATE = 'APPROVAL_DEPARTMENT_UPDATE'
export const APPROVAL_DEPARTMENT_DELETE = 'APPROVAL_DEPARTMENT_DELETE'
export const APPROVAL_DEPARTMENT_DETAIL = 'APPROVAL_DEPARTMENT_DETAIL'

export const APPROVAL_CONFIGURE_ACTIVATE = 'APPROVAL_CONFIGURE_ACTIVATE'
export const APPROVAL_CONFIGURE_INACTIVATE = 'APPROVAL_CONFIGURE_INACTIVATE'

// MASTER TYPES
export const GET_ALL_COUNTRY = 'GET_ALL_COUNTRY'
export const GET_REGION = 'GET_REGION'
export const GET_ALL_STATE = 'GET_ALL_STATE'

// PURCHASE REQUEST
export const PURCHASE_REQUEST_LIST = 'PURCHASE_REQUEST_LIST'
export const PURCHASE_REQUEST_DETAIL = 'PURCHASE_REQUEST_DETAIL'
export const PURCHASE_REQUEST_CREATE = 'PURCHASE_REQUEST_CREATE'
export const PURCHASE_REQUEST_APRROVE = 'PURCHASE_REQUEST_APRROVE'

export const CATEGORY = 'CATEGORY'
export const LIST_FILTER_CATEGORY = 'LIST_FILTER_CATEGORY'

export const CONTACT_US = 'CONTACT_US'

export const USER_PROFILE = 'USER_PROFILE'

// ADDRESS
export const ADDRESS_LIST = 'ADDRESS_LIST'
export const ADDRESS_DETAIL = 'ADDRESS_DETAIL'
export const ADDRESS_CREATE = 'ADDRESS_CREATE'
export const ADDRESS_UPDATE = 'ADDRESS_UPDATE'
export const ADDRESS_DELETE = 'ADDRESS_DELETE'

// ADDRESS DELIVERY
export const SHIPPING_ADDRESS_LIST = 'SHIPPING_ADDRESS_LIST'
export const SHIPPING_ADDRESS_DETAIL = 'SHIPPING_ADDRESS_DETAIL'
export const SHIPPING_ADDRESS_CREATE = 'SHIPPING_ADDRESS_CREATE'
export const SHIPPING_ADDRESS_UPDATE = 'SHIPPING_ADDRESS_UPDATE'
export const SHIPPING_ADDRESS_DELETE = 'SHIPPING_ADDRESS_DELETE'

// ADDRESS BILLING
export const BILLING_ADDRESS_LIST = 'BILLING_ADDRESS_LIST'
export const BILLING_ADDRESS_DETAIL = 'BILLING_ADDRESS_DETAIL'
export const BILLING_ADDRESS_CREATE = 'BILLING_ADDRESS_CREATE'
export const BILLING_ADDRESS_UPDATE = 'BILLING_ADDRESS_UPDATE'
export const BILLING_ADDRESS_DELETE = 'BILLING_ADDRESS_DELETE'

// BANK
export const BANK_LIST = 'BANK_LIST'
export const BANK_CREATE = 'BANK_CREATE'
export const BANK_UPDATE = 'BANK_UPDATE'
export const BANK_DELETE = 'BANK_DELETE'

// company profile
export const COMPANY_PROFILE = 'COMPANY_PROFILE'
export const UPDATE_COMPANY_PROFILE = 'UPDATE_COMPANY_PROFILE'
export const COMPANY_CONTACTS = 'COMPANY_CONTACTS'

export const DETAIL_PRICE = 'DETAIL_PRICE'
export const CART_PRICE = 'CART_PRICE'

//MOU
export const MOU_LIST = 'MOU_LIST'
export const DETAIL_MOU = 'DETAIL_MOU'
export const SAVE_MOU_ITEMS = 'SAVE_MOU_ITEMS'
export const LOG_TRANSACTION_MOU = 'LOG_TRANSACTION_MOU'
export const MOU_DELIVERY_ADDRESS = 'MOU_DELIVERY_ADDRESS'
export const MOU_SUBMIT_PO = 'MOU_SUBMIT_PO'

//REPORT FILE
export const REPORT_FILE = 'REPORT_FILE'

//TIMELINE
export const TIMELINE = 'TIMELINE'

export const GLOBAL_LOADING = 'GLOBAL_LOADING'
export const GLOBAL_ERROR = 'GLOBAL_ERROR'

//PROMO PAGES
export const PROMO_PAGES = 'PROMO_PAGES'
export const GET_DETAIL_CATEGORY_SLUG = 'GET_DETAIL_CATEGORY_SLUG'

// SHIPPING_TRACKING
export const SHIPPING_TRACKING = 'SHIPPING_TRACKING'

// BUDGET
export const BUDGET_LIST = 'BUDGET_LIST'
export const BUDGET_CREATE = 'BUDGET_CREATE'
export const BUDGET_DETAIL = 'BUDGET_DETAIL'
export const BUDGET_HISTORY = 'BUDGET_HISTORY'
export const BUDGET_LIST_VALID = 'BUDGET_LIST_VALID'
