import { combineReducers } from 'redux'
import { PROVINCES, CITIES } from '../types'

const initialValue = {
	isLoading: true,
	data: [],
	isError: false,
}

const citiesReducer = (state = initialValue, action) => {
	switch (action.type) {
		case `${CITIES}_PENDING`:
			return {
				...state,
				isLoading: true,
			}
		case `${CITIES}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
			}
		case `${CITIES}_REJECTED`:
			return {
				...state,
				isError: true,
			}
		default:
			return {
				...state,
			}
	}
}

const provincesReducer = (state = initialValue, action) => {
	switch (action.type) {
		case `${PROVINCES}_PENDING`:
			return {
				...state,
				isLoading: true,
			}
		case `${PROVINCES}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
			}
		case `${PROVINCES}_REJECTED`:
			return {
				...state,
				isError: true,
			}
		default:
			return {
				...state,
			}
	}
}

export default combineReducers({
	provincies: provincesReducer,
	cities: citiesReducer,
})
