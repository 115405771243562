import { UPDATE_COMPANY_PROFILE } from '../../types'

const initialState = {
	isLoading: false,
	isError: false,
	data: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${UPDATE_COMPANY_PROFILE}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${UPDATE_COMPANY_PROFILE}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${UPDATE_COMPANY_PROFILE}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
				data: action.payload.error,
			}
		case `${UPDATE_COMPANY_PROFILE}_RESET`:
			return initialState
		default:
			return initialState
	}
}
