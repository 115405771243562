import { GLOBAL_ERROR, GLOBAL_LOADING } from '../types'

const initialState = {
	loading: false,
	isError: false,
	errorData: {
		message: '',
		title: '',
	},
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `SET_${GLOBAL_LOADING}`:
			return {
				...state,
				loading: action.payload,
				isError: false,
				errorData: state.errorData,
			}
		case `SET_${GLOBAL_ERROR}`:
			return {
				...state,
				isError: action.payload.isError,
				errorData: {
					message: action.payload.message,
					title: action.payload.title,
				},
			}
		case `RESET_${GLOBAL_ERROR}`:
			return {
				...state,
				loading: false,
				isError: false,
				errorData: {
					message: '',
					title: '',
				},
			}
		default:
			return state
	}
}
