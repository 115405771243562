import { BANK_ACCOUNT } from '../types'
import { combineReducers } from 'redux'

const initialState = {
	isLoading: true,
	isError: false,
	data: [],
}

const mbizAccountReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${BANK_ACCOUNT}_MBIZ_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${BANK_ACCOUNT}_MBIZ_FULFILLED`:
			return {
				...state,
				isError: false,
				isLoading: false,
				data: action.payload.data,
			}
		case `${BANK_ACCOUNT}_MBIZ_REJECTED`:
			return {
				...state,
				isError: true,
				isLoading: false,
			}
		default:
			return state
	}
}

const partnerAccountReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${BANK_ACCOUNT}_PARTNER_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${BANK_ACCOUNT}_PARTNER_FULFILLED`:
			return {
				...state,
				isError: false,
				isLoading: false,
				data: action.payload.data,
			}
		case `${BANK_ACCOUNT}_PARTNER_REJECTED`:
			return {
				...state,
				isError: true,
				isLoading: false,
			}
		default:
			return state
	}
}

export default combineReducers({
	partnerAccount: partnerAccountReducer,
	mbizAccount: mbizAccountReducer,
})
