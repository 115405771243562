import { FORGOT_PASSWORD } from '../../types'
const initialState = {
	data: [],
	isLoading: false,
	isError: false,
	error: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${FORGOT_PASSWORD}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${FORGOT_PASSWORD}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
			}
		case `${FORGOT_PASSWORD}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
				error: action.payload.err,
			}
		case `${FORGOT_PASSWORD}_RESET`:
			return initialState
		default:
			return state
	}
}
