import { APPROVAL_EPROC_DELETE } from '../../../types'

const initialState = {
	isLoading: false,
	isError: false,
	data: null,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${APPROVAL_EPROC_DELETE}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
			}
		case `${APPROVAL_EPROC_DELETE}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
			}
		case `${APPROVAL_EPROC_DELETE}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isError: true,
				error: action.payload.error,
			}
		case `${APPROVAL_EPROC_DELETE}_RESET`:
			return initialState
		default:
			return initialState
	}
}
