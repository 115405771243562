import React from 'react'
import {
	createGenerateClassName,
	StylesProvider,
} from '@material-ui/core/styles'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import PropTypes from 'prop-types'
import theme from './src/assets/theme'

export const generateClassName = createGenerateClassName({
	productionPrefix: 'prod',
})

const WrapperComponent = ({ children }) => (
	<MuiThemeProvider theme={theme}>
		<StylesProvider generateClassName={generateClassName} injectFirst>
			<CssBaseline />
			{children}
		</StylesProvider>
	</MuiThemeProvider>
)

WrapperComponent.propTypes = {
	children: PropTypes.node,
}

export default WrapperComponent
