/* eslint-disable no-undef */
import ReduxThunk from 'redux-thunk'
import ReduxPromiseMiddleware from 'redux-promise-middleware'

const middlewares = []

middlewares.push(ReduxThunk)
middlewares.push(ReduxPromiseMiddleware)

export default middlewares
