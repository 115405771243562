import { DEPARTMENT_UPDATE } from '../../../types'

const initialState = {
	isLoading: false,
	isLoadingData: false,
	isError: false,
	alert: false,
	data: [],
}

export default (state = initialState, action) => {
	switch (action.type) {
		case `${DEPARTMENT_UPDATE}_PENDING`:
			return {
				...state,
				isLoading: true,
				isError: false,
				data: [],
			}
		case `${DEPARTMENT_UPDATE}_FULFILLED`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload.data,
				alert: true,
			}
		case `${DEPARTMENT_UPDATE}_LOAD_DATA`:
			return {
				...state,
				isLoadingData: true,
				isLoading: false,
				isError: false,
			}
		case `${DEPARTMENT_UPDATE}_DATA`:
			return {
				...state,
				isLoadingData: false,
				isError: false,
				data: action.payload.data,
			}
		case `${DEPARTMENT_UPDATE}_REJECTED`:
			return {
				...state,
				isLoading: false,
				isLoadingData: false,
				isError: true,
				data: [],
			}
		case `${DEPARTMENT_UPDATE}_CLEAR`:
			return {
				...state,
				isLoading: false,
				isError: false,
				data: [],
				alert: false,
			}
		case `${DEPARTMENT_UPDATE}_RESET`:
			return initialState
		default:
			return state
	}
}
